import { TYPE } from "vue-toastification";
import { apiCall, logResponse } from "@/core/api";
import { showAlert } from "@/core/popup";

export async function getPrivilegeList(): Promise<any> {
  const resource = "/privileges";
  const response = await apiCall("GET", resource);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function getPrivilege(privilegeId: string): Promise<any> {
  const resource = `/privileges/${privilegeId}`;
  const response = await apiCall("GET", resource);
  if (response.code === 200) {
    return response.result[0];
  }
  logResponse(response);
  return undefined;
}

export async function deletePrivilege(privilegeId: string): Promise<any> {
  const resource = `/privileges/${privilegeId}`;
  const response = await apiCall("DELETE", resource);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function addPrivilege(privilege: any): Promise<any> {
  const resource = "/privileges";
  const response = await apiCall("POST", resource, privilege);
  if (response.code === 200) {
    return response.result;
  }

  if (response.code === 406) {
    showAlert(TYPE.WARNING, "Privilege with the same id exists.");
    return undefined;
  }

  logResponse(response);
  return undefined;
}

export async function editPrivilege(privilege: any): Promise<any> {
  const resource = "/privileges";
  const response = await apiCall("PUT", resource, privilege);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}
